import React from 'react';
import { Link } from 'gatsby';
import Fade from 'react-reveal/Fade';

import styles from './index.module.css';

const Card = props => {
  let button;
  const {
    buttonText,
    route,
    backgroundColor,
    className,
    title,
    subtitle,
    desc,
    eventUrl,
  } = props;
  if (route) {
    button = (
      <Link to={route}>
        <button type="button" className={styles.button}>
          {buttonText}
        </button>
      </Link>
    );
  } else {
    button = (
      <a
        href={eventUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.button}
      >
        {buttonText}
      </a>
    );
  }

  return (
    <div
      style={{
        backgroundColor,
      }}
      className={className}
    >
      <Fade bottom cascade>
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
          <hr
            style={{
              height: '4px',
              width: '50px',
              borderWidth: '0',
              textAlign: 'left',
              marginLeft: '0',
              backgroundColor: '#bd2026',
            }}
          />
          <p className={styles.desc}>{desc}</p>
          {route || eventUrl ? (
            <Fade up duration={2500}>
              {button}
            </Fade>
          ) : null}
        </div>
      </Fade>
    </div>
  );
};

export default Card;
